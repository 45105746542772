













import Vue from 'vue';

export default Vue.extend({
    name: 'breadcrumbs',
    computed: {
        breadcrumbs(): any {
            return this.$store.state.breadcrumbs.history;
        }
    }
});
